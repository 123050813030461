import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import useApi from "../api/ApiWorks";

export default function OKPayment(props) {
  const location = useLocation();
  const nav = useNavigate();
  const params = new URLSearchParams(location.search);
  const sessId = params.get("sid");
  const { getCheckoutStatus } = useApi();
  const [isFetching, setIsFetching] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsFetching(true);
    getCheckoutStatus(sessId)
      .then((r) => {
        setIsFetching(false);
        let ule = r.Voucher;
        window.location.replace(ule);
      })
      .catch((e) => {
        setIsFetching(false);
        setHasError(true);
        setError(e?.response?.data?.message);
      });
    // eslint-disable-next-line
  }, []);

  const handleHome = (evt) => {
    nav("/");
  };

  return (
    <div className="bg-blue-200 flex flex-col items-center justify-center min-h-screen md:py-2">
      <main className="flex items-center w-full px-2 md:px-20">
        <div className="hidden md:inline-flex flex-col flex-1 space-y-1">
          <img alt="logo" src="ndia.png" width={700} height={750} />
        </div>
        {isFetching && (
          <div className="bg-gray-200 rounded-2xl shadow-2xl flex flex-col w-full md:w-1/3 items-center max-w-4xl transition duration-1000 ease-out">
            <div class="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-blue-600" />
          </div>
        )}
        {hasError && (
          <div className="bg-gray-200 rounded-2xl shadow-2xl flex flex-col w-full md:w-1/3 items-center max-w-4xl transition duration-1000 ease-out">
            <p className="font-medium text-lg leading-1 text-red-700">
              {error}
            </p>
            <button
              className="rounded-2xl m-2 text-white text-center align-content-center bg-blue-400 px-4 py-2 shadow-md hover:text-red-400 hover:bg-white transition duration-200 ease-in"
              onClick={handleHome}
            >
              Go Home
            </button>
          </div>
        )}
      </main>
    </div>
  );
}
