import axios from "axios";

export default function useApi() {
  const BASE_URL = "https://payment.diakemsl.com/api/v1";

  const createCheckOut = async (item) => {
    const url = BASE_URL + "/pay/create";
    const resp = await axios.post(url, { ...item });
    console.info(resp);
    return resp.data;
  };

  const getCheckoutStatus = async (sid) => {
    const url = BASE_URL + "/pay/status/" + sid + "/success";
    const resp = await axios.put(url);
    return resp.data;
  };

  const setCheckoutStatusCancelled = async (sid) => {
    const url = BASE_URL + "/pay/status/" + sid + "/cancel";
    const resp = await axios.put(url);
    return resp.data;
  };

  return {
    createCheckOut,
    getCheckoutStatus,
    setCheckoutStatusCancelled,
  };
}
