export default function useDiakem() {
  const locations = [
    { id: "Airport", label: "Freetown Int. Airport" },
    { id: "Others", label: "Other locations" },
  ];

  const bundleTypes = [
    { id: "Time", label: "Time based bundles" },
    { id: "Volume", label: "Volume based bundles" },
  ];

  const airportBundles = [
    { id: "38", label: "30 Mins - SLE 50", amount: 50 },
    { id: "39", label: "60 Mins - SLE 75", amount: 75 },
    { id: "40", label: "3 Hrs - SLE 150", amount: 150 },
  ];

  const otherVolumeBundles = [
    { id: "38", label: "0.75GB – SLE 12", amount: 12 },
    { id: "42", label: "1.5GB - SLE  22", amount: 22 },
    { id: "43", label: "2.5GB - SLE 38", amount: 43 },
    { id: "44", label: "6GB - SLE 90", amount: 90 },
    { id: "45", label: "10GB - SLE 150", amount: 150 },
    { id: "46", label: "15GB - SLE 230", amount: 230 },
    { id: "47", label: "25GB- SLE 380", amount: 380 },
    { id: "50", label: "35GB- SLE 530", amount: 530 },
    { id: "51", label: "50GB- SLE 755", amount: 755 },
    { id: "52", label: "85GB - SLE 1280", amount: 1280 },
    { id: "49", label: "100GB – SLE 1500", amount: 1500 },
  ];

  const otherTimeBundles = [
    { label: "1 Hr - SLE 10", id: "53", amount: 10 },
    { label: "3 Hrs - SLE 28", id: "55", amount: 28 },
    { label: "1 Day - SLE 70", id: "56", amount: 70 },
    { label: "3 Days - SLE 175", id: "54", amount: 175 },
    { label: "1 Week - SLE 355", id: "57", amount: 355 },
    { label: "1 Month – SLE 800", id: "58", amount: 800 },
  ];

  const allBundles = [
    { id: "38", label: "30 Mins - SLE 50", amount: 50 },
    { id: "39", label: "60 Mins - SLE 75", amount: 75 },
    { id: "40", label: "3 Hrs - SLE 150", amount: 150 },
    { id: "38", label: "0.75GB – SLE 12", amount: 12 },
    { id: "42", label: "1.5GB - SLE  22", amount: 22 },
    { id: "43", label: "2.5GB - SLE 38", amount: 43 },
    { id: "44", label: "6GB - SLE 90", amount: 90 },
    { id: "45", label: "10GB - SLE 150", amount: 150 },
    { id: "46", label: "15GB - SLE 230", amount: 230 },
    { id: "47", label: "25GB- SLE 380", amount: 380 },
    { id: "50", label: "35GB- SLE 530", amount: 530 },
    { id: "51", label: "50GB- SLE 755", amount: 755 },
    { id: "52", label: "85GB - SLE 1280", amount: 1280 },
    { id: "49", label: "100GB – SLE 1500", amount: 1500 },
    { label: "1 Hr - SLE 10", id: "53", amount: 10 },
    { label: "3 Hrs - SLE 28", id: "55", amount: 28 },
    { label: "1 Day - SLE 70", id: "56", amount: 70 },
    { label: "3 Days - SLE 175", id: "54", amount: 175 },
    { label: "1 Week - SLE 355", id: "57", amount: 355 },
    { label: "1 Month – SLE 800", id: "58", amount: 800 },
  ];

  const getAmount = (bundle) => {
    let t_bundle = allBundles.filter((bdl) => bdl.label === bundle);
    return t_bundle[0];
  };

  return {
    locations,
    bundleTypes,
    airportBundles,
    otherVolumeBundles,
    otherTimeBundles,
    getAmount,
  };
}
