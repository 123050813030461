import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import useApi from "../api/ApiWorks";

const RejectPayment = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessId = params.get("sid");
  const toast = useRef(null);
  const nav = useNavigate();
  const { setCheckoutStatusCancelled } = useApi();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    show("Voiding payment ...", "info");
    setCheckoutStatusCancelled(sessId)
      .then((r) => {
        setTimeout(() => {
          console.log("D ...");
        }, 3000);
        nav("/");
      })
      .catch((e) => {
        clear();
        setHasError(true);
        show("Error: " + e?.response?.data?.message, "error");
        setTimeout(() => {
          console.log("Delayed for 15 second.");
        }, 15000);
        nav("/");
      });

    // eslint-disable-next-line
  }, []);

  const clear = () => {
    toast.current.clear();
  };

  const show = (message, inval) => {
    toast.current.show({ severity: inval, summary: message });
  };

  return (
    <div className="bg-blue-200 flex align-items-center justify-content-center min-h-screen md:py-2">
      <Toast
        ref={toast}
        className={
          hasError
            ? "bg-red-400 rounded-2xl shadow-2xl w-full"
            : "bg-blue-400 rounded-2xl shadow-2xl w-full"
        }
        content={({ message }) => (
          <section
            className={
              hasError
                ? "bg-red-400 shadow-2xl w-full p-3 gap-3"
                : "bg-blue-400 shadow-2xl w-full p-3 gap-3"
            }
            style={{ borderRadius: "10px" }}
          >
            <i className="pi pi-cloud-upload text-primary-500 text-2xl"></i>
            <div className="flex flex-column gap-3 w-full">
              <p className="m-0 font-semibold text-base text-white">
                {message.summary}
              </p>
              <p className="m-0 text-base text-700">{message.detail}</p>

              {/* Inputs    <div className="flex gap-3 mb-3">*/}
              <Button
                label="Close"
                text
                className="text-white p-0"
                onClick={clear}
              ></Button>
            </div>
            {/*  </div> */}
          </section>
        )}
      />
      <div className="flex items-center justify-content-center justify-items-center w-full px-2 md:px-20">
        <div className="flex-col flex-grow gap-4">
          <img alt="logo" src="diakem_logo.png" width={300} height={200} />

          <p className="font-medium text-lg leading-1 text-purple-600">
            Your reliable ISP partner in Sierra Leone
            <br />
            <br />
            DIAKEM Investments (SL) Limited has been providing and supporting{" "}
            <br />
            unique cutting-edge ICT options that assist its customers secure the{" "}
            <br />
            appropriate solution for businesses and recreation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RejectPayment;
