import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import App from "./App";
import OKPayment from "./pages/OKPayment";
import RejectPayment from "./pages/RejectPayment";

const AppWrapper = (props) => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/pay/ok" element={<OKPayment message="Ok called" />} />
      <Route
        path="/pay/cancel"
        element={<RejectPayment message="Cancel Called" />}
      />
    </Routes>
  );
};

export default AppWrapper;
