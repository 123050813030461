import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ComboBox from "../Components/ComboBox";
import useApi from "../api/ApiWorks";
import useDiakem from "../utils/Diakem";

const Login = (props) => {
  const CheckoutPreview = ({
    subtitle = "Sign In!",
    title = "DaCathé",
    actionPrimary = "Sign In",
    actionPrompt = "Don't have an account?",
    actionSecondary = "Create new account?",
    refId = new Date()
      .toISOString()
      .replace("T", "")
      .replace("-", "")
      .replace("-", "")
      .replace(":", "")
      .replace(":", "")
      .replace(".", "")
      .replace("Z", ""),
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({ mode: "onBlur" });

    const {
      locations,
      bundleTypes,
      airportBundles,
      otherTimeBundles,
      otherVolumeBundles,
      getAmount,
    } = useDiakem();

    const { createCheckOut } = useApi();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [cellno, setCellno] = useState("");
    const [rid, setRid] = useState(refId);
    const [netcomm, setNetComm] = useState(false);
    const [isAirport, setIsAirport] = useState(true);
    const [location, setLocation] = useState("");
    const [btype, setBtype] = useState("");
    const [bundle, setBundle] = useState("");
    const [bundles, setBundles] = useState(airportBundles);
    const [amount, setAmount] = useState(airportBundles[0].amount);
    const [antamediaId, setAntamediaId] = useState(airportBundles[0].id);

    const onSubmit = async (data) => {
      setNetComm(true);
      console.info(data);
      console.info(antamediaId);
      console.info(location);

      let dt = { ...data };
      dt.amount = amount;
      dt.desc = antamediaId + ":" + location;
      console.info(dt);
      createCheckOut(dt).then((r) => {
        console.info(r);
        setNetComm(false);
        if (r?.checkoutUrl) {
          window.location.replace(r.checkoutUrl);
        }
      });
    };

    const bdlChange = (evt) => {
      let val = evt.target.value;
      let amt = getAmount(val);
      setBundle(val);
      setAmount(amt.amount);
      setAntamediaId(amt.id);
    };

    const btChange = (evt) => {
      let val = evt.target.value;
      setBtype(val);
      if (val.split(" ")[0] === "Time") {
        setBundles(otherTimeBundles);
        setAmount(otherTimeBundles[0].amount);
        setAntamediaId(otherTimeBundles[0].id);
      } else {
        setBundles(otherVolumeBundles);
        setAmount(otherVolumeBundles[0].amount);
        setAntamediaId(otherVolumeBundles[0].id);
      }
    };

    const locChange = (evt) => {
      let val = evt.target.value;
      setLocation(val);
      if (val.split(" ")[0] === "Freetown") {
        setBundles(airportBundles);
        setIsAirport(true);
        setAmount(airportBundles[0].amount);
        setAntamediaId(airportBundles[0].id);
      } else {
        setIsAirport(false);
        setBundles(otherTimeBundles);
        setAmount(otherTimeBundles[0].amount);
        setAntamediaId(otherTimeBundles[0].id);
      }
    };

    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-gray-200 rounded-2xl shadow-2xl flex flex-col w-full md:w-1/3 items-center max-w-4xl transition duration-1000 ease-out"
      >
        <h2 className="p-3 text-3xl font-bold text-purple-800">{title}</h2>
        <div className="inline-block border-[1px] justify-center w-20 border-blue-400 border-solid"></div>
        <h3 className="text-xl font-semibold text-blue-400 pt-2">{subtitle}</h3>
        {/* Inputs */}
        <div className="flex flex-col items-center justify-center">
          <input
            id="username"
            name="username"
            type="text"
            className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-blue-400 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
            placeholder="Name"
            {...register("username", {
              required: "Name is required",
              minLength: {
                value: 3,
                message: "Username must be at least 3 characters",
              },
            })}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus={true}
          ></input>
          {errors?.username && (
            <p className="text-xs italic text-red-500">
              {errors?.username?.message}
            </p>
          )}
          <input
            id="email"
            name="email"
            type="email"
            className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-blue-400 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
            placeholder="Email"
            {...register("email", {
              required: "Email is required",
            })}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>
          <input
            type="text"
            className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-blue-400 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
            placeholder="Phone (Optional)"
            {...register("cellno")}
            onChange={(e) => setCellno(e.target.value)}
            value={cellno}
          ></input>
          <ComboBox
            value={location}
            handleChange={locChange}
            options={locations}
            id="loc"
            title="Select location"
            cname="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-blue-400 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0 bg-white"
          />
          {isAirport === false && (
            <ComboBox
              value={btype}
              options={bundleTypes}
              handleChange={btChange}
              title="Select bundle type"
              id="btype"
              cname="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-blue-400 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0 bg-white"
            />
          )}

          <ComboBox
            value={bundle}
            handleChange={bdlChange}
            options={bundles}
            title="Select bundle"
            id="bdl"
            cname="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-blue-400 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0 bg-white"
          />

          <input
            type="hidden"
            className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-blue-400 m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0"
            placeholder="Ref. Id"
            {...register("rid")}
            onChange={(e) => setRid(e.target.value)}
            value={rid}
          ></input>
          <button
            loading={netcomm}
            className="rounded-2xl m-2 text-white text-center align-content-center bg-blue-400 w-full px-4 py-2 shadow-md hover:text-red-400 hover:bg-white transition duration-200 ease-in"
          >
            {actionPrimary}
          </button>
          {/* Inputs 
          <button className="rounded-2xl m-2 text-white bg-blue-400 w-2/5 px-4 py-2 shadow-md hover:text-blue-400 hover:bg-white transition duration-200 ease-in">
            {actionPrimary}
          </button>
          */}
        </div>
        <div className="inline-block border-[1px] justify-center w-20 border-blue-400 border-solid"></div>
        <p className="text-blue-400 mt-4 text-sm">{actionPrompt}</p>
        <p className="text-blue-400 mb-4 text-sm font-medium cursor-pointer">
          {actionSecondary}
        </p>
      </form>
    );
  };

  return (
    <div className="bg-blue-200 flex flex-col items-center justify-center min-h-screen md:py-2">
      <main className="flex items-center w-full px-2 md:px-20">
        <div className="hidden md:inline-flex flex-col flex-1 space-y-1">
          <img alt="logo" src="ndia.png" width={700} height={750} />
        </div>
        <CheckoutPreview
          amount={10}
          subtitle="Buy data bundle"
          title="DIAKEM"
          actionPrimary="Pay now"
          actionPrompt=""
          actionSecondary=""
        />
      </main>
    </div>
  );
};

export default Login;
